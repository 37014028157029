import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { AppBar, Avatar, Button, Container, Grid, IconButton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { getAuth, signOut } from "firebase/auth";
import { default as React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROLES } from '../../app/config';
import { getFreeTrialDaysLeft } from '../../app/roleUtils';
import Spacer from '../common/Spacer';
import PayPalSubscribe from '../topup/PayPalSubscribe';
import { getUser } from '../user/UserSlice';
import AccountStat from './AccountStat';

function Account(props)
{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    
    const handleLogout = async () =>
    {
        
        const auth = getAuth();
        try
        {
            await signOut(auth);
            console.log('User successfully logged out');
            navigate("/login", { replace: true });

        } catch (error)
        {
            console.error('Error logging out:', error);
        }
    };

    const freeDaysLeft = getFreeTrialDaysLeft();

    const getRoleDescription = () =>
    {
        if (user.role === ROLES.GUEST)
        {
            if (freeDaysLeft > 0)
                return <Typography variant="h6">FREE TRIAL ENDS IN {freeDaysLeft} DAYS</Typography>;
            else
                return <Typography variant="h6">YOUR FREE TRIAL IS OVER</Typography>;
        }

        if (user.role === ROLES.SUBSCRIBER)
        {
            return (<Stack sx={{ alignItems: 'center' }}>
                <Typography variant="h6">PREMIUM SUBSCRIPTION</Typography>
                <Typography variant="account_stat">Automatic renewal on {dayjs(user.subscriptionCheckTime).format('DD. MMM YYYY')}</Typography>
                <Typography variant="account_stat">Cancel anytime by logging into your PayPal account</Typography>
            </Stack>);
        }

        if (user.role === ROLES.ADMIN)
        {
            return <Typography variant="h6">ADMIN</Typography>;
        }

    }

    const getSubscribeButton = () =>
    {
        if (user.role === ROLES.GUEST)
        {
            return <PayPalSubscribe />;
        }
        return null;
    }
    
    return (
        <Container disableGutters maxWidth="sm">
            <AppBar
                position="relative"
                variant='blurry'
            >
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <IconButton onClick={() => navigate("/", { replace: true })}>
                        <ArrowBackIosNewRoundedIcon />
                    </IconButton>
                    <Grid item justifyContent="center" alignItems="center">
                        <Typography variant="h3" color="text.primary">Your Account</Typography>
                    </Grid>
                    <Spacer width={50} />
                </Grid>
                
                
            </AppBar>
            <Stack sx={{ width: '100%', alignItems: 'center' }}>
                <Stack sx={{ width: '85%', alignItems: 'center' }} spacing={0} >
                    <Spacer height={20} />
                    <Typography variant="account_stat">{user.email}</Typography>
                    <Spacer height={10} />
                    <Avatar alt={user.name} src={user.photoURL} sx={{ border: '3px solid #E272DC', width: 56, height: 56 }} referrerPolicy="no-referrer" />
                    <Spacer height={10} />
                    <Typography variant="h2">{user.name}</Typography>
                    <Typography variant="account_stat">Member since {dayjs(user.created).format('DD. MMM YYYY')}</Typography>
                    <Spacer height={10} />
                    {getRoleDescription()}
                    <Spacer height={30} />
                    {getSubscribeButton()}
                    <Spacer height={30} />
                    <Typography variant="h4">Your Stats</Typography>
                    <Spacer height={10} />
                    <Stack spacing={1} direction='row' >
                        <AccountStat label='Started Sessions' value={user.started_sessions} />
                        <AccountStat label='Completed Sessions' value={user.completed_sessions} />
                        <AccountStat label='Completion Rate' value={user.started_sessions > 0 ? Math.round((user.completed_sessions / user.started_sessions)*100) + '%' : '0%'} />
                    </Stack>
                    <Spacer height={30} />
                    <Button variant='outline_premium' onClick={handleLogout}>Logout</Button>
                    <Spacer height={30} />
                    <Typography variant="h6">Read our <a rel='noreferrer' target='_blank' href='https://olivya.ai/terms-of-service/'>Terms of Service</a> & <a rel='noreferrer' target='_blank' href='https://olivya.ai/privacy-policy/'>Privacy Policy</a>.</Typography>
                    <Typography variant="account_stat">Rise and Play ©️ 2024 – All Rights Reserved</Typography>
                </Stack></Stack>
        </Container>
    );
}

export default Account;
