import { showError } from './showError';
import { BACKEND_URL, EMPTY_SESSION } from './config';
import * as Sentry from "@sentry/react";

export const ai_getMyMessage = async (audioBlob, sessionId, callback) =>
{
    try
    {
        const formData = new FormData();
        formData.append('file', audioBlob, sessionId + '.mp3'); // Name the file according to its session ID
        
        const response = await fetch(BACKEND_URL + 'getMyMessage', {
            method: 'POST',
            headers: {

            },
            body: formData,
        });

        const data = await response.json();
        callback(data);

    } catch (error)
    {
        Sentry.captureException(error);
        console.error('ai_getMyMessage: ', error);
        showError('getMyMessage: ' + error.toString());
        callback('error');
    }
}

export const ai_getOlivyaMessage = async (messages, callback) =>
{
    try
    {
        const response = await fetch(BACKEND_URL + 'getOlivyaMessage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ messages: messages })
        });

        const data = await response.json();
        callback(data);

    } catch (error)
    {
        Sentry.captureException(error);
        console.error('getOlivyaMessage: ', error);
        showError('getOlivyaMessage: ' + error.toString());
        callback('error');
    }
}

export const ai_getInitialOlivyaMessage = async (messages, callback) =>
{
    try
    {
        const response = await fetch(BACKEND_URL + 'getInitialOlivyaMessage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ messages: messages })
        });

        const data = await response.json();
        callback(data);

    } catch (error)
    {
        Sentry.captureException(error);
        console.error('getInitialOlivyaMessage: ', error);
        showError('getInitialOlivyaMessage: ' + error.toString());
        callback('error');
    }
}

export const ai_generateSpeech = async (message, callback) =>
{
    try
    {
        const response = await fetch(BACKEND_URL + 'generateSpeech', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ message: message })
        });

        const data = await response.json();
        callback(data.audio);

    } catch (error)
    {
        Sentry.captureException(error);
        console.error('ai_generateSpeech: ', error);
        showError('generateSpeech: ' + error.toString());
        callback('error');
    }
}

export const ai_getSessionSummary = async (messages, callback) =>
{
    if (messages.length <= 4)
    {
        callback({ summary: JSON.stringify({ short_summary: EMPTY_SESSION, long_summary: EMPTY_SESSION }) })
        return;
    }

    try
    {
        const response = await fetch(BACKEND_URL + 'getSessionSummary', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ messages: messages })
        });

        const data = await response.json();
        callback(data);

    } catch (error)
    {
        Sentry.captureException(error);
        console.error('getSessionSummary: ', error);
        showError('getSessionSummary: ' + error.toString());
    }
}

export const ai_getWeeklyReport = async (report, messages, callback) =>
{
    try
    {
        const response = await fetch(BACKEND_URL + 'getWeeklyReport', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ messages: messages })
        });

        const data = await response.json();
        report.summary = data.summary;
        callback(report);

    } catch (error)
    {
        Sentry.captureException(error);
        console.error('getWeeklySummary: ', error);
        showError('getWeeklySummary: ' + error.toString());
    }
}

export const ai_createThread = async (callback) =>
{
    try
    {
        const response = await fetch(BACKEND_URL + 'createThread', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = await response.json();
        callback(data);

    } catch (error)
    {
        Sentry.captureException(error);
        console.error('sendMesssage: ', error);
        showError('sendMesssage: ' + error.toString());
    }
}
