import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { AppBar, Backdrop, Button, Container, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import { default as React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { am_logEvent } from '../../app/amplitude';
import { fs_updateProperty, fs_updateSession, fs_updateUserProperty } from '../../app/firestore';
import { ai_getSessionSummary } from '../../app/openai';
import Spacer from '../common/Spacer';
import LoadingScreen from '../dialog/LoadingScreen';
import { getSession, getUser, setUserProperty } from '../user/UserSlice';
import SessionRating from './SessionRating';
import dayjs from 'dayjs';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

function SessionCompleted(props)
{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const session = useSelector(getSession);

    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const [sessionSummaryText, setSessionSummaryText] = useState('');


    useEffect(() =>
    {
        window.scrollTo(0, 0);

        if (session.status !== 'completed')
        {
            setOpenBackdrop(true);
            ai_getSessionSummary(session.messages, onGetSessionSummary);
        }
        if (session.status === 'completed')
        {
            setSessionSummaryText(session.long_summary);
        }

        // cleanup function
        return () =>
        {
        };
    }, []);

    const onQuitToMainMenu = () =>
    {
        navigate("/", { replace: true });
    }

    const onGetSessionSummary = result => 
    {
        const sessionSummary = JSON.parse(result.summary);
        sessionSummary.status = 'completed';
        sessionSummary.finished = dayjs().format();
        sessionSummary.length_seconds = Math.floor(dayjs().diff(dayjs(session.created)) / 1000);

        fs_updateSession(session.id, sessionSummary, onSummarySaved);
        fs_updateUserProperty(user, 'completed_sessions', user.completed_sessions + 1, () => { });
        dispatch(setUserProperty({ property: 'completed_sessions', value: user.completed_sessions + 1 }));

        setSessionSummaryText(sessionSummary.long_summary);

        am_logEvent('level_end', {
            level_name: user.completed_sessions + 1,
            success: true,
            length_seconds: sessionSummary.length_seconds
        });
    };

    const onSummarySaved = () =>
    {
        setOpenBackdrop(false);
    }

    const onChangeRating = (value) =>
    {
        fs_updateProperty(session.id, 'rating', value, onSummarySaved);
    }

    return (
        <Container disableGutters maxWidth="sm">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <LoadingScreen />
            </Backdrop>
            <AppBar
                position='relative'
                variant='blurry'
            >
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <IconButton onClick={onQuitToMainMenu}>
                        <ArrowBackIosNewRoundedIcon />
                    </IconButton>
                    <Grid item justifyContent="center" alignItems="center">
                        <Typography variant="h2" color="text.lightGreen">Session Completed!</Typography>
                    </Grid>
                    <Spacer />
                </Grid>
            </AppBar>
            <Stack sx={{ width: '100%', alignItems: 'center' }}>
                <Stack sx={{ width: '85%' }} spacing={5}>
                    <Paper variant="olivyaMessage">
                        <Typography variant="h4">How was Olivya in this session?</Typography>
                        <Spacer height={10} />
                        <SessionRating onChangeValue={onChangeRating} value={session.rating} />
                    </Paper>
                    {session.goals !== undefined && session.goals.length > 0 && <Stack spacing={1}>
                        <Typography variant="h2">My Goals:</Typography>
                        <Typography variant="long_session_summary">{session.goals.map((element, index) =>
                        {
                            return '- ' + element + '\n';
                        })}</Typography>
                    </Stack>}
                    {session.actions !== undefined && session.actions.length > 0 &&
                        <Stack spacing={1}>
                            <Typography variant="h2">My Next Steps:</Typography>
                            <Typography variant="long_session_summary">{session.actions.map((element, index) =>
                            {
                                return '- ' + element + '\n';
                            })}</Typography>
                        </Stack>}
                    {session.when !== undefined && session.actions !== undefined && session.actions.length > 0 &&
                        <AddToCalendarButton
                            name={session.short_summary}
                            description={'Goals: ' + session.goals.join('. ') + "\n\n" + 'Actions: ' + session.actions.join('. ')}
                            startDate={dayjs(session.when).format('YYYY-MM-DD')}
                            startTime={dayjs(session.when).format('HH:mm')}
                            endTime={dayjs(session.when).add(60, 'minute').format('HH:mm')}
                            timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                            organizator="Olivya|talk@olivya.ai"
                            location="https://talk.olivya.ai"
                            options="'Apple','Google','MicrosoftTeams','iCal'"
                            buttonStyle="round"
                            hideBackground="false"
                            trigger="click"
                            label="Add a reminder to your calendar"
                            lightMode="dark"
                            styleDark="--btn-font-weight: 300;--font: Montserrat;--btn-border: #00000040;--btn-border-radius: 15px;--btn-background: #00000040;--btn-shadow: rgba(0 0 0 / 40%) 0.3em 0.3em 0.6em;"
                            size="6"
                        >
                        </AddToCalendarButton>}
                    <Stack spacing={2}>
                        <Typography variant="h2">Session Summary:</Typography>
                        <Typography variant="long_session_summary">{sessionSummaryText}</Typography>
                        {session.emotions !== undefined && session.emotions.length > 0 && <Typography variant="long_session_summary">I was feeling <b>{session.emotions.join(', ')}</b>.</Typography>}
                    </Stack>
                    <Button
                        onClick={() => window.open("https://olivya.ai/coaches/", "_blank")}
                        variant="outline_premium"
                        endIcon={<ArrowForwardIosRoundedIcon />}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            Do you feel like you need more support on this topic?
                            <Spacer height={15} />
                            <Typography variant="caption">Talk to our recommended <b>coach for you!</b></Typography>
                        </Grid>
                    </Button>
                    <Spacer height={1} />

                </Stack></Stack>
        </Container>
    );
}

export default SessionCompleted;
