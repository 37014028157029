import { Button, Container, Grid, Typography, Stack } from '@mui/material';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fs_loginUser } from '../../app/firestore';
import { loginUser } from '../../features/user/UserSlice';
import Spacer from '../common/Spacer';
import TSDialog from '../dialog/TSDialog';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import * as Sentry from "@sentry/react";
import { useEffect } from 'react';
import { am_logEvent } from '../../app/amplitude';

const Login = () =>
{
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isLoggingIn, setIsLoggingIn] = useState(false);

    useEffect(() =>
    {
        window.scrollTo(0, 0);
        am_logEvent('funnel_open_login');

        //TODO: Check if subscription is active, and it needs updating the status

        // cleanup function
        return () => { };
    }, []);


    const handleLogin = async (e) =>
    {
        e.preventDefault();
        setIsLoggingIn(true);

        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        am_logEvent('funnel_tap_login');
        signInWithPopup(auth, provider)
            .then((result) =>
            {
                // User successfully logged into Google, get their data from Firestore
                fs_loginUser(handleLoginUserData);
            })
            .catch((error) =>
            {
                // Handle error
                Sentry.captureException(error);
                console.error(error);
                setIsLoggingIn(false);
            });
    };

    const handleLoginUserData = (user) =>
    {
        // Add user data to store
        dispatch(loginUser(user));
        am_logEvent('funnel_finish_login');
        if (user.onboarding)
            navigate('/onboarding', { replace: true });
        else
            navigate('/', { replace: true });
    }

    return (
        <Container maxWidth="sm">
            <Stack>
                <Spacer height={30} />
                <Carousel
                    showArrows={false}
                    showStatus={false}
                    showThumbs={false}
                    autoPlay={false}
                    interval={8000}
                    infiniteLoop={true}
                    useKeyboardArrows={true}
                    swipeable={true}
                    dynamicHeight={false}
                    emulateTouch={true}
                    autoFocus={true}
                    axis='horizontal'
                >
                    <Stack justifyContent="center" alignItems="center">
                        <Spacer height={50} />
                        <img src="logo-full-512.png" alt="olivya logo" style={{ width: '70%', height: 'auto' }} />
                        <Spacer height={30} />
                        <Typography variant="h6">Your <b>AI coach companion</b>,
                            <br />dedicated to <b>unblock</b> you every day
                            <br /><b>in just 30 minutes</b>.</Typography>
                        <Spacer height={40} />
                    </Stack>

                    <Stack justifyContent="center" alignItems="center" >
                        <Spacer height={10} />
                        <img src="/img/work-development.png" alt="professional development" style={{ width: '65%', height: 'auto' }} />
                        <Spacer height={10} />
                        <Typography variant="h4" sx={{ textAlign: 'center' }}>Professional Growth</Typography>
                        <Spacer height={10} />
                        <Typography variant="h6" style={{ width: '70%' }}>Achieve your career goals, improve work-life balance, and boost your self-confidence</Typography>
                        <Spacer height={40} />
                    </Stack>
                    <Stack justifyContent="center" alignItems="center">
                        <Spacer height={10} />
                        <img src="/img/personal-development.png" alt="personal development" style={{ width: '70%', height: 'auto' }} />
                        <Spacer height={10} />
                        <Typography variant="h4" sx={{ textAlign: 'center' }}>Personal Development</Typography>
                        <Spacer height={10} />
                        <Typography variant="h6" style={{ width: '70%' }}>Gain deeper insights into your personal challenges and opportunities</Typography>
                        <Spacer height={40} />
                    </Stack>
                    <Stack justifyContent="center" alignItems="center">
                        <Spacer height={10} />
                        <img src="/img/relationship-development.png" alt="relationship development" style={{ width: '60%', height: 'auto' }} />
                        <Spacer height={10} />
                        <Typography variant="h4" sx={{ textAlign: 'center' }}>Relationship Enhancement</Typography>
                        <Spacer height={10} />
                        <Typography variant="h6" style={{ width: '70%' }}>Navigate and strengthen your personal relationships</Typography>
                        <Spacer height={40} />
                    </Stack>
                    <Stack justifyContent="center" alignItems="center">
                        <Spacer height={20} />    
                        <Typography variant="survey_question" style={{ width: '90%', fontSize: '16px' }}>"Olivya made me understand that we all had skills gaps to address in our team."</Typography>
                        <Spacer height={30} />
                        <img src="/img/AnnaHarris.png" alt="testimonial" style={{ width: '80px', height: '80px' }} />
                        <Spacer height={10} />
                        <Typography variant="h5" sx={{ textAlign: 'center' }}>Claire</Typography>
                        <Typography variant="h6" sx={{ textAlign: 'center' }}>CEO and Co-Founder</Typography>
                        <Spacer height={40} />
                    </Stack>

                </Carousel>
                <Spacer height={40} />
                <Stack
                    direction="column"
                    sx={{ width: '250px' }}
                    alignSelf='center'
                >
                    {isLoggingIn && <Typography variant="h6">Logging in...</Typography>}
                    {!isLoggingIn && <Button
                        startIcon={<GoogleIcon />}
                        variant="login"
                        onClick={handleLogin}
                    >Login with Google
                    </Button>}

                </Stack>
            </Stack>
            <TSDialog />
        </Container>
    );
};

export default Login;
