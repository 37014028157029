import Timeline from '@mui/lab/Timeline';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import * as React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUser, setWeeklyReport } from '../user/UserSlice';
import SessionsTimelineItem from './SessionsTimelineItem';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator'; 
import TimelineDot from '@mui/lab/TimelineDot';
import { useNavigate } from 'react-router-dom';
import { EXTRA_SESSION_COST, ROLES } from '../../app/config';
import { useEffect } from 'react';
import { getFreeTrialDaysLeft } from '../../app/roleUtils';
import { Button, Stack, Typography } from '@mui/material';
import { theme } from '../../app/styles';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import Spacer from '../common/Spacer';

export default function SessionsTimeline(props)
{
    const user = useSelector(getUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    dayjs.extend(relativeTime);
    const daysUntilNextSunday = (7 - dayjs().day()) % 7 || 7;
    // Helper function to get the start of the week
    const getStartOfWeek = (date) =>
    {
        return dayjs(date).endOf('week');
    };

    const getWhenDate = date =>
    {
        const daysAway = dayjs().diff(date, 'day');
        if (daysAway > 14)
            return date.format('D. MMM');
        else
            return date.fromNow();
    }

    const freeDaysLeft = getFreeTrialDaysLeft();

    const dailySessionButton = () =>
    {
        // If user in free trial or a subscriber, show the 'start session' button
        if ((user.role === ROLES.GUEST && freeDaysLeft > 0) || user.role === ROLES.SUBSCRIBER || user.role === ROLES.ADMIN)
        {
            if (props.sessions.length === 0 || !dayjs(props.sessions[0].created).isSame(dayjs(), 'day'))
                return <SessionsTimelineItem
                    when=""
                    title="START NEW SESSION"
                    color="white"
                    icon="PlayArrowOutlinedIcon"
                    buttonVariant="timeline_bright"
                    onClick={() => props.onLoadSession({ id: 0, status: 'started' })}
                />;

            return <SessionsTimelineItem
                when=""
                title={"You had a session today!\nSTART EXTRA SESSION*"}
                color="white"
                icon="LockOutlinedIcon"
                buttonVariant="timeline_bright"
                
                onClick={() => props.onPurchaseSession()}
            />
        }
        
        return (<SessionsTimelineItem
            when=""
            title={"Start New Session (" + EXTRA_SESSION_COST + " Tokens)"}
            color="primary"
            icon="LockOutlinedIcon"
            buttonVariant="timeline_outline_premium"
            onClick={() => props.onPurchaseSession()}
        />);
    }

    const getSessionColor = status =>
    {
        switch (status)
        {
            case "completed": return "lightGreen";
            case "abandoned": return "whiteTransparent";
            case "started": return "lightestBlue";
            default: return "lightestBlue";
        }
    }

    const getSessionTitleColor = status =>
    {
        switch (status)
        {
            case "completed": return "white";
            case "abandoned": return "whiteTransparent";
            case "started": return "lightestBlue";
            default: return "lightestBlue";
        }
    }

    const getSessionIcon = status =>
    {
        switch (status)
        {
            case "completed": return "CheckOutlinedIcon";
            case "abandoned": return "DeleteForeverOutlinedIcon";
            case "started": return "AddOutlinedIcon";
            default: return "AddOutlinedIcon";
        }
    }

    useEffect(() =>
    {
        generateTimelineItems();

        // cleanup function
        return () =>
        {
        };
    }, [props.sessions]);
    
    const [timelineItems, setTimelineItems] = useState([]);

    const generateTimelineItems = () =>
    {
        let lastWeekStart = null;
        let _timelineItems = [];

        props.sessions.forEach(session =>
        {
            const sessionDate = dayjs(session.created);
            const sessionWeekStart = getStartOfWeek(sessionDate);

            if (!sessionWeekStart.isSame(getStartOfWeek(dayjs())) && (!lastWeekStart || !sessionWeekStart.isSame(lastWeekStart)))
            {
                // Insert WeeklyReportTimelineItem at the start of each week
                _timelineItems.push(<SessionsTimelineItem key={`weekly-${sessionWeekStart}`}
                    when={getWhenDate(sessionWeekStart)}
                    title={'WEEK #' + sessionWeekStart.week() + ' REPORT'}
                    color='primary'
                    icon='InsightsRoundedIcon'
                    buttonVariant="timeline_button"
                    onClick={e => onWeeklyReportClick(sessionWeekStart.week())}
                />);
                lastWeekStart = sessionWeekStart;
            }
            
            // Insert the session item
            _timelineItems.push(<SessionsTimelineItem
                key={session.id}
                when={getWhenDate(dayjs(session.created))}
                title={session.short_summary}
                color={getSessionColor(session.status)}
                textColor={getSessionTitleColor(session.status)}
                icon={getSessionIcon(session.status)}
                stars={session.stars}
                rating={session.rating}
                buttonVariant="timeline_button"
                disabled={session.status}
                onClick={() => props.onLoadSession(session)}
            />);

        });

        setTimelineItems(_timelineItems);
    }

    const onWeeklyReportClick = week =>
    {
        dispatch(setWeeklyReport(week));
        navigate("/report", { replace: true });
    }
    
    return (
        <Timeline sx={{
            [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
            },
            padding: '0px 20px 0px 20px',
            marginBlockStart: 0,
            marginBlockEnd: 0,
        }}>
            <SessionsTimelineItem
                when={'in ' + daysUntilNextSunday + " days"}
                title="NEW WEEKLY REPORT"
                color="whiteTransparent"
                icon="InsightsRoundedIcon"
                buttonVariant="timeline_button"
                disabled='abandoned'
            />
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineConnector sx={{ bgcolor: theme.palette["whiteTransparent"].main }} />
                    <TimelineDot color="whiteTransparent" variant="outlined">
                        <CalendarMonthOutlinedIcon sx={{ color: theme.palette["whiteTransparent"].main }} />
                    </TimelineDot>
                    <TimelineConnector sx={{ bgcolor: theme.palette["whiteTransparent"].main }} />
                </TimelineSeparator>
                <TimelineContent sx={{ m: 'auto 0', lineHeight: '1', paddingLeft: '30px', }}>
                    <Stack>
                    <Typography variant="timeline_date" component="span" sx={{ color: theme.palette["whiteTransparent"].main, padding: '0px 0px 3px 0px' }}>
                        in a few days
                        </Typography>
                        <AddToCalendarButton
                            name="Coaching session with Olivya"
                            description="You have scheduled a session with Olivya. Simply login at https://talk.olivya.ai to start your session."
                            startDate={dayjs().add(2, 'day').format('YYYY-MM-DD')}
                            startTime="09:30"
                            endTime="10:00"
                            timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                            organizator="Olivya|talk@olivya.ai"
                            location="https://talk.olivya.ai"
                            options="'Apple','Google','MicrosoftTeams','iCal'"
                            buttonStyle="round"
                            hideBackground="false"
                            trigger="click"
                            label="SCHEDULE A SESSION"
                            lightMode="dark"
                            styleDark="--btn-font-weight: 300;--font: Montserrat;--btn-border: #00000040;--btn-border-radius: 15px;--btn-background: #00000040;--btn-shadow: rgba(0 0 0 / 40%) 0.3em 0.3em 0.6em;"
                            size="4"
                        >
                        </AddToCalendarButton>
                        <Spacer height={10} />
                    </Stack>
                </TimelineContent>
            </TimelineItem>
            {((user.role === ROLES.GUEST && freeDaysLeft > 0) ||
                user.role === ROLES.SUBSCRIBER ||
                user.role === ROLES.ADMIN) && (
                <SessionsTimelineItem
                    when="tomorrow"
                    title="Daily Session will be available"
                    color="whiteTransparent"
                    icon="LockOutlinedIcon"
                    buttonVariant="timeline_button"
                    disabled='abandoned'
                />)}
            {dailySessionButton()}
            {timelineItems}
            {props.sessions.length > 20 && !props.allSessionsLoaded &&
                <SessionsTimelineItem
                when={''}
                title="Load Older Sessions"
                color="lightestBlue"
                icon='CloudDownloadOutlinedIcon'
                buttonVariant="outline"
                onClick={props.onLoadOlderSessions}
            />}
            <SessionsTimelineItem
                when={getWhenDate(dayjs(user.created))}
                title="I started my journey with Olivya"
                color="whiteTransparent"
                buttonVariant="timeline_button"
                disabled='abandoned'
            />

        </Timeline>
    );
}
