import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { STARS_1, STARS_2, STARS_3 } from '../../app/config';
function LinearProgressWithLabel({ currentValue, max, ...props })
{
    const newValue = Math.ceil((currentValue / max) * 100);

    const marks = [
        {
            value: STARS_1, 
            label: '⚝',
        },
        {
            value: STARS_2,
            label: '⚝',
        },
        {
            value: STARS_3,
            label: '⚝',
        },
    ];

    const formatSeconds = (totalSeconds) =>
    {
        // Pad seconds with leading zero if less than 10
        const minutes = Math.floor(totalSeconds / 60);
        const paddedMinutes = minutes.toString().padStart(2, '0');
        const seconds = totalSeconds % 60;
        const paddedSeconds = seconds.toString().padStart(2, '0');
        return `${paddedMinutes}:${paddedSeconds}`;
    };

    const labelValue = formatSeconds(currentValue);
    
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1, position: 'relative', margin: 0 }}>
                <Slider value={currentValue} marks={marks} {...props} max={max} />
                <Typography variant="h4" color="white" sx={{ position: 'absolute', top: 5, right: 1, width: '60px', }}>
                    {labelValue}
                </Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    currentValue: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
};

const BorderLinearProgress = styled(LinearProgressWithLabel)(({ theme }) => ({

    color: '#ffffff20',
    boxShadow: '0 3px 8px 0px #00000030',
    transition: 'none',
    height: 32,
    borderRadius: 20,
    border: '1px solid #ffffff21',
    padding: '0 !important',
    cursor: 'default',
    '& .MuiSlider-track': {
        border: 'none',
        borderBottomLeftRadius: '20px',
        borderTopLeftRadius: '20px',
        borderBottomRightRadius: '0px',
        borderTopRightRadius: '0px',
        paddingLeft: '10px',
    },
    '& .MuiSlider-thumb': {
        display: 'none'
    },
    '& .MuiSlider-mark': {
        backgroundColor: '#fa70cd50',
        width: '1px',
        height: '20px',
        borderRadius: 0
    },
    '& .MuiSlider-markLabel': {
        fontSize: '18px',        
    },

}));

export default BorderLinearProgress;