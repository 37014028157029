import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
function ProgressTimer({ currentValue, maxValue, ...props })
{
    const newValue = Math.ceil((currentValue / maxValue) * 100);
    
    const finalLabel = currentValue + " / " + maxValue + " sessions completed!";

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1, position: 'relative' }}>
                <LinearProgress variant="determinate" value={newValue} {...props} />
                <Typography variant="long_session_summary" color="white" sx={{ color:'white', position: 'absolute', top: 6, width:'100%', textAlign:'center' }}>
                    {finalLabel}
                </Typography>
            </Box>
        </Box>
    );
}

ProgressTimer.propTypes = {
    currentValue: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
};

const SessionsProgressBar = styled(ProgressTimer)(({ theme }) => ({
    height: 32,
    borderRadius: 20,
    border: '1px solid #ffffff40',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: 'transparent',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 0,
        backgroundColor: '#ffffff40',
    },
}));

export default SessionsProgressBar;