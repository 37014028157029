import { fetchFile } from '@ffmpeg/util';
import MicNoneRoundedIcon from '@mui/icons-material/MicNoneRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import TokenRoundedIcon from '@mui/icons-material/TokenRounded';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import { AppBar, Avatar, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ShowMoreText from "react-show-more-text";
import { ReactTyped } from "react-typed";
import { useFFmpeg } from '../../app/FFmpegContext';
import { am_logEvent } from '../../app/amplitude';
import { EXTEND_SESSION_COST, EXTEND_SESSION_SECONDS, SESSION_SECONDS, EMPTY_SESSION } from '../../app/config';
import { fs_addSessionMessage, fs_createSession, fs_getSessionMessages, fs_loadSessions, fs_updateSession, fs_updateUserProperty } from '../../app/firestore';
import { ai_generateSpeech, ai_getInitialOlivyaMessage, ai_getMyMessage, ai_getOlivyaMessage, ai_getSessionSummary } from '../../app/openai';
import Spacer from '../common/Spacer';
import Header from '../session/Header';
import VAD from '../session/VAD';
import VADOlivya from '../session/VADOlivya';
import TopUpTokens from '../topup/TopUpTokens';
import { addSessionMessage, getLastSession, getSession, getUser, setSession, setSessionMessages, setUserProperty } from '../user/UserSlice';
import LoadSession from './LoadSession';
import * as Sentry from "@sentry/react";
import { getTimeOfDayText } from '../../app/dateUtils';

function Session()
{
    const { ffmpeg, ffmpegLoad, ffmpegIsLoaded, ffmpegProgress } = useFFmpeg();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const session = useSelector(getSession);
    const lastSession = useSelector(getLastSession);

    const findLastMessage = (messages, role) =>
    {
        for (var i = messages.length - 1; i >= 0; i--)
        {
            if (messages[i].role === role)
                return messages[i].content;
        }
        return '...';
    }

    const [isRecording, setRecording] = useState(false);
    const [isRecordingDisabled, setIsRecordingDisabled] = useState(false);
    const [isSendingDisabled, setIsSendingDisabled] = useState(true);
    const [isSessionComplete, setIsSessionComplete] = useState(false);

    const [isMyMessageLoading, setIsMyMessageLoading] = React.useState(false);
    const [myAudioBlob, setMyAudioBlob] = useState(null);
    const [myMessage, setMyMessage] = useState('Record your first voice message to start the conversation!');

    const [isOlivyaMessageLoading, setIsOlivyaMessageLoading] = React.useState(false);
    const [olivyaAudioBlob, setOlivyaAudioBlob] = useState(null);
    const [olivyaMessage, setOlivyaMessage] = useState('...');

    const [openCompleteDialog, setOpenCompleteDialog] = React.useState(false);
    const [openExtendDialog, setOpenExtendDialog] = React.useState(false);
    const [openTopUpDialog, setOpenTopUpDialog] = React.useState(false);
    
    const [isOlivyaAudioLoaded, setIsOlivyaAudioLoaded] = React.useState(false);
    const [isSessionLoaded, setIsSessionLoaded] = useState(false);
    const [isAllLoaded, setIsAllLoaded] = useState(false);
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const [isSessionFirstTimeStarting, setIsSessionFirstTimeStarting] = useState(true);

    const [olivyaWaveSurfer, setOlivyaWaveSurfer] = useState(null);

    // handle ffmpeg not being loaded (eg. user refreshed the page)
    useEffect(() =>
    {
        if (!ffmpegIsLoaded)
            ffmpegLoad();

    }, [ffmpegIsLoaded]);

    // Wait for ffmpeg, session messages and initial voice to be loaded
    useEffect(() =>
    {
        setIsAllLoaded(ffmpegIsLoaded && isSessionLoaded && isOlivyaAudioLoaded);

        return () => { };
    }, [ffmpegIsLoaded, isSessionLoaded, isOlivyaAudioLoaded]);

    //create new session, or load messages if it already exists
    useEffect(() =>
    {
        window.scrollTo(0, 0);

        //If new session, create it
        if (session.id === 0)
        {
            fs_createSession(onSessionCreated);
        }
        else
        {
            setIsSessionFirstTimeStarting(false);
            fs_getSessionMessages(session.id, onSessionMessagesLoaded);
        }

        return () => { };
    }, []);

    const onSessionCreated = newSession =>
    {
        dispatch(setSession(newSession));
   
        // If first session, make an introduction
        if (user.started_sessions === 0)
        {
            getInitialOlivyaMessage(newSession.id, "Introduce yourself in 3 sentences. Explain about your coaching style and objectives in 2 sentences. Ask the client what they would like to talk about in this session. The client's name is " + user.name.split(" ")[0] + ".")
            return;
        }

        // If there's a valid last session, remind the client about it
        if (lastSession !== undefined && lastSession.status === 'completed' && lastSession.long_summary !== EMPTY_SESSION)
        {
            getInitialOlivyaMessage(newSession.id, "This is a summary of the previous session: " + lastSession.long_summary + ". Remind the client about it and ask if they want to revisit it or talk about a new topic. The client's name is " + user.name.split(" ")[0] + ".")
        }
        else
        {
            // For a regular session, just start simply
            let newMessages = [];
            let firstMessage = {
                role: "assistant",
                content: "Good " + getTimeOfDayText() + " " + user.name.split(" ")[0] + ", what would you like to talk about?"
            };
            newMessages.push(firstMessage);
            fs_addSessionMessage(newSession.id, firstMessage, () => { });
            dispatch(setSessionMessages(newMessages));
            
            updateStartedSessionsCount();
            finishSessionLoading(newMessages);
        }
    }

    const getInitialOlivyaMessage = (sessionId, newMessage) =>
    {
        let newMessages = [];
        let firstMessage = {
            role: "assistant",
            content: newMessage
        };
        newMessages.push(firstMessage);
        
        ai_getInitialOlivyaMessage(newMessages, response =>
        {
            const olivyaMessage = {
                role: 'assistant',
                content: response.message
            };

            fs_addSessionMessage(sessionId, olivyaMessage, () => { });
            dispatch(addSessionMessage(olivyaMessage));

            let finishMessages = [...newMessages];
            finishMessages.push(olivyaMessage);

            updateStartedSessionsCount();
            finishSessionLoading(finishMessages);
        });
    }

    const onSessionMessagesLoaded = messages =>
    {
        dispatch(setSessionMessages(messages));
        finishSessionLoading(messages);
    }

    const finishSessionLoading = (messages) =>
    {
        // If there's no last user's message (eg. new session), show default template
        let lastUserMessage = findLastMessage(messages, 'user');
        if (lastUserMessage === '...') 
            setMyMessage('Record your first voice message to start the conversation!');
        else
            setMyMessage(lastUserMessage);

        const lastMessage = findLastMessage(messages, 'assistant');
        setOlivyaMessage(lastMessage);

        if (!isLoadingScreen)
            generateVoice(lastMessage);

        setIsSessionLoaded(true);
    }

    const updateStartedSessionsCount = () =>
    {
        fs_updateUserProperty(user, 'started_sessions', user.started_sessions + 1, () => { });
        dispatch(setUserProperty({ property: 'started_sessions', value: user.started_sessions + 1 }));
        am_logEvent('level_start', { level_name: user.started_sessions + 1 });
    }

    const generateVoice = async (text) =>
    {
        ai_generateSpeech(text, audioBlob =>
        {
            setOlivyaAudioBlob(convertBase64ToBlob(audioBlob));
            setIsOlivyaMessageLoading(false);
            setIsRecordingDisabled(false);
            setIsLoadingScreen(false);
        });
    };

    //when user's audio blob is set, start the process: convert to mp3, send for transcription, send to LLM, send to TTS
    useEffect(() =>
    {
        if (myAudioBlob)
        {
            convertAndProcessMyAudioBlob(myAudioBlob);
        }

    }, [myAudioBlob]);

    const convertAndProcessMyAudioBlob = async (blob) =>
    {
        try
        {
            await ffmpeg.writeFile('input.mp3', await fetchFile(URL.createObjectURL(blob)));
            await ffmpeg.exec([
                '-i', 'input.mp3',
                '-b:a', '32k',
                '-ac', '1',
                '-codec:a', 'libmp3lame',
                'output.mp3']);
            const data = await ffmpeg.readFile('output.mp3');

            const newAudioBlob = new Blob([data.buffer], { type: 'audio/mp3' });
            ai_getMyMessage(newAudioBlob, session.id, onMyMessageReceived);

        } catch (e)
        {
            Sentry.captureException(e);
            return null;
        }
    }

    const onMyMessageReceived = response =>
    {
        if (response === 'error')
        {
            setIsRecordingDisabled(false);
            return;
        }

        const myMessage = {
            role: 'user',
            content: response.message
        };

        fs_addSessionMessage(session.id, myMessage, () => { });
        dispatch(addSessionMessage(myMessage));
        setMyMessage(myMessage.content);
        setIsMyMessageLoading(false);

        const newMessages = [...session.messages];
        newMessages.push(myMessage);
        ai_getOlivyaMessage(newMessages, onOlivyaMessageReceived);
    }

    const onOlivyaMessageReceived = response =>
    {
        if (response === 'error')
        {
            setIsRecordingDisabled(false);
            return;
        }

        const olivyaMessage = {
            role: 'assistant',
            content: response.message
        };

        fs_addSessionMessage(session.id, olivyaMessage, () => { });
        dispatch(addSessionMessage(olivyaMessage));
        setOlivyaMessage(olivyaMessage.content);

        setOlivyaAudioBlob(convertBase64ToBlob(response.audio));
        setIsOlivyaMessageLoading(false);
        setIsRecordingDisabled(false);

        //detect if the session is completed
        if (response.complete === true)
        {
            setIsRecordingDisabled(true);
            ai_getSessionSummary(session.messages, onGetSessionSummary);
        }
    }

    const onGetSessionSummary = result => 
    {
        const sessionSummary = JSON.parse(result.summary);
        sessionSummary.status = 'completed';
        sessionSummary.finished = dayjs().format();
        sessionSummary.length_seconds = Math.floor(dayjs().diff(dayjs(session.created)) / 1000);

        fs_updateUserProperty(user, 'completed_sessions', user.completed_sessions + 1, () => { });
        dispatch(setUserProperty({ property: 'completed_sessions', value: user.completed_sessions + 1 }));
        am_logEvent('level_end', {
            level_name: user.completed_sessions + 1,
            success: true,
            length_seconds: sessionSummary.length_seconds
        });

        let newSession = { ...session, ...sessionSummary };
        dispatch(setSession(newSession));

        // save to DB, and set the flag that the session is complete
        fs_updateSession(session.id, sessionSummary, () => setIsSessionComplete(true));
    };

    const convertBase64ToBlob = (base64Audio) =>
    {
        // Decode the base64 string into a binary string
        const binaryString = atob(base64Audio);

        // Create a Uint8Array to hold the binary string
        const byteArray = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++)
        {
            byteArray[i] = binaryString.charCodeAt(i);
        }

        // Create a Blob from the byteArray
        const blob = new Blob([byteArray], { type: 'audio/aac' });
        return blob;
    }

    const onMarkAsComplete = () =>
    {
        closeAllDialogs();
        let newSession = { ...session };
        newSession.status = 'started';
        dispatch(setSession(newSession));
        navigate("/session_completed", { replace: true });
    }

    const closeAllDialogs = () =>
    {
        setOpenCompleteDialog(false);
        setOpenExtendDialog(false);
    }

    const handleExtendSession = () =>
    {
        if (user.tokens >= EXTEND_SESSION_COST)
        {

            fs_updateUserProperty(user, 'tokens', user.tokens - EXTEND_SESSION_COST, () =>
            {
                dispatch(setUserProperty({ property: 'tokens', value: user.tokens - EXTEND_SESSION_COST }));
                dispatch(setSession({ ...session, expires: dayjs().add(EXTEND_SESSION_SECONDS, 'seconds').format() }));
                setIsSessionComplete(false);
                setOpenExtendDialog(false);
                am_logEvent('spend_virtual_currency', { value: EXTEND_SESSION_COST, virtual_currency_name: 'Tokens', item_name: 'Extend Session' });
            });
        }
        else
        {
            setOpenExtendDialog(false);
            setOpenTopUpDialog(true);
        }
    }

    const onSessionStartClick = () =>
    {
        olivyaWaveSurfer.play();

        setIsOlivyaMessageLoading(true);
        generateVoice(findLastMessage(session.messages, 'assistant'));  

        if(isSessionFirstTimeStarting)
            dispatch(setSession({ ...session, created: dayjs().format(), expires: dayjs().add(SESSION_SECONDS, 'seconds').format() }));
    }

    const onOlivyaAudioReady = wavesurfer =>
    {
        setOlivyaWaveSurfer(wavesurfer);
        setIsOlivyaAudioLoaded(true);
    }

    const onStartRecordingPress = e =>
    {
        setMyMessage('Recording...');
        setRecording(true);
    }

    //Disable the recording button after clicking on it
    useEffect(() =>
    {
        let timer;

        if (isRecording)
        {
            setIsSendingDisabled(true);
            timer = setTimeout(() => setIsSendingDisabled(false), 3000);
        }
        
        // Cleanup function to clear the timer if the component unmounts before the timeout
        return () => clearTimeout(timer);
    }, [isRecording]);

    const handleStarsChange = newStars =>
    {
        fs_updateSession(session.id, { stars: newStars }, () =>
        {
            let newSession = { ...session, stars: newStars };
            dispatch(setSession(newSession));
        });
    }

    return (
        <Container disableGutters maxWidth="sm" sx={{ paddingLeft: '10px', paddingRight: '10px' } } >
            <Header
                onComplete={() => setOpenCompleteDialog(true)}
                onTimerFinish={() => setOpenExtendDialog(true)}
                created={session.created}
                expires={session.expires}
                hasSessionStarted={!isLoadingScreen}
                hasSessionCompleted={isSessionComplete}
                onStarsChange={handleStarsChange}
            />
            <Stack sx={{ width: '100%', position: 'relative' }} spacing={0}>
                <Spacer height={40} />
                <Stack spacing={0} sx={{ position: 'relative' }}>
                    <Avatar
                        alt="Olivya"
                        src="https://talk.olivya.ai/logo192.png"
                        sx={{
                            border: '2px solid #C571F6',
                            position: 'absolute',
                            top: -30,               // Aligns the Avatar to the top of the parent
                            left: '49%',          // Centers the Avatar horizontally
                            transform: 'translateX(-50%)',
                            zIndex: 1000,
                        }}
                    />
                    <Paper variant="olivyaMessage" sx={{ position: 'relative' }}>
                        <VADOlivya
                            audioBlob={olivyaAudioBlob}
                            onReady={onOlivyaAudioReady}
                            isRecording={isRecording} />
                    </Paper>
                    <Paper variant="olivyaMessageVAD" sx={{ position: 'relative' }}>
                        <Spacer height={10} />
                        <ShowMoreText
                            lines={3}
                            more="Show more"
                            less="Show less"
                            className="content-css"
                            anchorClass="show-more-less-clickable"
                            expanded={false}
                            truncatedEndingComponent={"... "}
                        >
                        </ShowMoreText>
                            <Typography variant="session_message">
                            <ReactTyped strings={[olivyaMessage]} typeSpeed={32} showCursor={false} />
                            </Typography>

                    </Paper>
                    {isOlivyaMessageLoading && (<div className={"loading-screen-olivya"}>
                        <div className="loading-spinner">
                            <svg viewBox="0 0 100 100">
                                <circle cx="50" cy="50" r="40" />
                            </svg>
                        </div>
                    </div>)}


                </Stack>


                {/* MY MESSAGE */}
                <Spacer height={60} />


                <Stack spacing={0} sx={{ position: 'relative' }}>
                    <Avatar
                        alt={user.name}
                        src={user.photoURL}
                        sx={{
                            border: '2px solid #9BBEFF',
                            position: 'absolute',
                            top: -30,               // Aligns the Avatar to the top of the parent
                            left: '49%',          // Centers the Avatar horizontally
                            transform: 'translateX(-50%)',
                            zIndex: 1000,
                        }}
                    />
                    <Paper variant="myMessage" >
                        <VAD isRecording={isRecording} onAudioRecorded={setMyAudioBlob} />
                    </Paper>
                    <Paper variant="myMessageVAD" >
                        <Spacer height={10} />
                        <Typography variant="session_message">
                            <ReactTyped strings={[myMessage]} typeSpeed={5} showCursor={false} />
                        </Typography>
                    </Paper>
                    {isMyMessageLoading && (<div className={"loading-screen-olivya"}>
                        <div className="loading-spinner">
                            <svg viewBox="0 0 100 100">
                                <circle cx="50" cy="50" r="40" />
                            </svg>
                        </div>
                    </div>)}
                </Stack>
                {isSessionComplete &&
                    <Stack sx={{ alignItems: 'center' }}>
                        <Spacer height={60} />
                        <Button
                            endIcon={<TaskOutlinedIcon />}
                            variant='session_completed'
                            onClick={e =>
                            {
                                navigate("/session_completed", { replace: true });
                             }}>READ SESSION SUMMARY
                            </Button>
                    </Stack>}
                <Spacer height={140} />
            </Stack>
            {!isSessionComplete && <AppBar
                position="fixed"
                variant='blurry'
                sx={{
                    top: 'auto',
                    bottom: 0,
                    zIndex: 1100,
                    padding: 2,
                    paddingBottom: '30px',
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                }}
            >
                <Stack sx={{ width: '100%', alignItems: 'center', }} >
                    {!isRecording ? (
                        <Stack sx={{ alignItems: 'center' }}>
                            <Button
                                disabled={isRecordingDisabled}
                                variant={isRecordingDisabled ? "outline_round" : "bright_round"}
                                onTouchStart={onStartRecordingPress}
                                onMouseDown={onStartRecordingPress}
                            >
                                <MicNoneRoundedIcon />
                            </Button>
                            <Typography variant="account_stat">{isRecordingDisabled ? "Please wait..." : "Tap to Record"}</Typography>
                        </Stack>) : (
                        <Stack sx={{ alignItems: 'center' }}>
                            <Button
                                disabled={isSendingDisabled}
                                variant={isSendingDisabled ? "outline" : "bright"}
                                onClick={e =>
                                {
                                    // STOP RECORDING
                                    setRecording(false);
                                    setIsOlivyaMessageLoading(true);
                                    setIsMyMessageLoading(true);
                                    setIsRecordingDisabled(true);
                                    setIsSendingDisabled(true);
                                }}><SendRoundedIcon /></Button>
                            <Typography variant="account_stat">{isSendingDisabled ? "Please wait..." : "Finish Recording"}</Typography>
                        </Stack>)}
                </Stack>
            </AppBar>}

            {isLoadingScreen && <LoadSession isLoaded={isAllLoaded} onClose={onSessionStartClick} progress={ffmpegProgress} />}

            <Dialog
                open={openCompleteDialog}
                onClose={closeAllDialogs}
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography><b>QUIT TO MAIN MENU</b></Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>Do you want to save this session?</Typography>
                </DialogContent>
                <DialogActions>
                    <Stack spacing={2}>
                        <Button endIcon={<SaveOutlinedIcon /> } variant='contained' color="lightGreen" onClick={onMarkAsComplete} >
                            SAVE
                        </Button>
                        <Button endIcon={<DeleteForeverOutlinedIcon />} variant='contained' color="lightRed"
                            onClick={e =>
                            {
                                closeAllDialogs();
                                am_logEvent('level_end', {
                                    level_name: user.started_sessions,
                                    success: false,
                                    length_seconds: Math.floor(dayjs().diff(dayjs(session.created)) / 1000)
                                });
                                navigate("/", { replace: true });
                            }} >
                            QUIT
                        </Button>
                        
                        <Button color="lightestBlue" onClick={closeAllDialogs}>Go Back</Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            <Dialog open={openExtendDialog} >
                <DialogTitle id="extend-session-dialog">
                    <Typography><b>Session timer is over!</b></Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>Your session has reached the time limit.</Typography>
                    <br />
                    <Typography>Would you like to complete or extend your session?</Typography>
                    <br />
                    <Typography>You have {user.tokens} Tokens!</Typography>
                </DialogContent>
                <DialogActions>
                    <Stack spacing={2}>
                        <Button
                            variant='contained'
                            color="lightGreen"
                            onClick={onMarkAsComplete}>
                            COMPLETE THE SESSION
                        </Button>
                        <Button
                            endIcon={<TokenRoundedIcon />}
                            variant='contained'
                            color="primary"
                            onClick={handleExtendSession} autoFocus>
                            EXTEND FOR 5 MINS -> {EXTEND_SESSION_COST}
                        </Button>
                        <Spacer />
                    </Stack>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openTopUpDialog}
                onClose={() => setOpenTopUpDialog(false)}
            >
                <DialogTitle id="top-up-dialog-session">
                    <Typography >Top Up Your Tokens</Typography>
                </DialogTitle>
                <DialogContent>
                    <TopUpTokens
                        onSuccess={() =>
                        {
                            setOpenExtendDialog(true);
                            setOpenTopUpDialog(false);
                        }}
                        onFail={() =>
                        {
                            setOpenTopUpDialog(false);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenTopUpDialog(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>

        </Container>
    );
}

export default Session;
