import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import InsightsRoundedIcon from '@mui/icons-material/InsightsOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Button, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { theme } from '../../app/styles';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

export default function SessionsTimelineItem(props)
{
    const customIcons = {
        0: {
            icon: <span />,
            label: '',
        },
        1: {
            icon: <SentimentVeryDissatisfiedIcon color="error" sx={{ fontSize: 14, paddingTop: '1px' }} />,
            label: 'Useless',
        },
        2: {
            icon: <SentimentDissatisfiedIcon color="error" sx={{ fontSize: 14, paddingTop: '1px' }} />,
            label: 'Poor',
        },
        3: {
            icon: <SentimentSatisfiedIcon color="warning" sx={{ fontSize: 14, paddingTop: '1px' }} />,
            label: 'Okay',
        },
        4: {
            icon: <SentimentSatisfiedAltIcon color="success" sx={{ fontSize: 14, paddingTop: '1px' }} />,
            label: 'Good',
        },
        5: {
            icon: <SentimentVerySatisfiedIcon color="success" sx={{ fontSize: 14, paddingTop: '1px' }} />,
            label: 'Excellent',
        },
    };

    const getIcon = name =>
    {
        switch (name)
        {
            case "CheckOutlinedIcon": return <CheckOutlinedIcon sx={{ color: theme.palette[props.color].main }} />;
            case "InsightsRoundedIcon": return <InsightsRoundedIcon sx={{ color: theme.palette[props.color].main }} />;
            case "LockOutlinedIcon": return <LockOutlinedIcon sx={{ color: theme.palette[props.color].main }} />;
            case "PlayArrowOutlinedIcon": return <PlayArrowOutlinedIcon sx={{ color: theme.palette[props.color].main }} />;
            case "AddOutlinedIcon": return <AddOutlinedIcon sx={{ color: theme.palette[props.color].main }} />;
            case "ClearOutlinedIcon": return <QuestionMarkRoundedIcon sx={{ color: theme.palette[props.color].main }} />;
            case "DeleteForeverOutlinedIcon": return <DeleteForeverOutlinedIcon sx={{ color: theme.palette[props.color].main }} />;
            case "CloudDownloadOutlinedIcon": return <CloudDownloadOutlinedIcon sx={{ color: theme.palette[props.color].main }} />;
            default: return <FiberManualRecordOutlinedIcon sx={{ color: theme.palette[props.color].main }} />
        }
    }

    const getStars = () =>
    {
        let output = '';
        for (var i = 0; i < props.stars; i++)
        {
            output += '⚝ ';
        }

        return output;
    }

    const getRating = () =>
    {
        if (props.rating === undefined || props.rating === null)
            return <span />;

        return customIcons[props.rating].icon;
    }

    const renderSubtitle = () =>
    {
        //if (props.rating !== undefined || props.stars > 0)
        if (props.stars > 0)
            return (
                <Stack direction='row' spacing={1}>
                    {/*getRating()*/}
                    <Typography
                        variant="timeline_stars"
                        component="span">
                        {getStars()}
                    </Typography>
                </Stack>);
    }
    
    return (
        <TimelineItem onClick={props.onClick}>
            <TimelineSeparator>
                <TimelineConnector sx={{ bgcolor: theme.palette[props.color].main }} />
                <TimelineDot color={props.color} variant="outlined">
                    {getIcon(props.icon)}
                </TimelineDot>
                <TimelineConnector sx={{ bgcolor: theme.palette[props.color].main }} />
            </TimelineSeparator>
            <TimelineContent sx={{ m: 'auto 0', lineHeight: '1' }}>
                <Button variant={props.buttonVariant} disabled={props.disabled === 'abandoned' ? true : false } >
                    <Stack>
                        <Typography variant="timeline_date" component="span" sx={{ color: props.textColor !== undefined ? props.textColor : theme.palette[props.color].main, padding: '0px 0px 3px 0px' }}>
                            {props.when}
                        </Typography>
                        <Typography variant="timeline_title" component="span" sx={{ display: 'flex', color: props.textColor !== undefined ? theme.palette[props.textColor].main : theme.palette[props.color].main, padding: '0px' }}>
                            {props.title} {getRating()}
                        </Typography>
                        {renderSubtitle()}
                    </Stack>
                </Button>
            </TimelineContent>
        </TimelineItem>
    );
}
