import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { default as React, useEffect, useState } from 'react';
import BorderLinearProgress from '../common/LinearProgressWithLabel';
import { STARS_1, STARS_2, STARS_3 } from '../../app/config';

function ResponsiveAppBar(props)
{
    dayjs.extend(advancedFormat);
    const { created, expires, hasSessionStarted, hasSessionCompleted, onTimerFinish, onComplete, onStarsChange } = props;

    const [timerTicks, setTimerTicks] = useState(Math.floor(dayjs().diff(dayjs(created)) / 1000));
    const [maxTicks, setMaxTicks] = useState(Math.floor(dayjs(expires).diff(dayjs(created)) / 1000));
    const [displayTicks, setDisplayTicks] = useState(0.1);

    const [timer, setTimer] = useState(0);
    const [stars, setStars] = useState(0);

    useEffect(() =>
    {
        let elapsedSeconds = Math.floor(dayjs().diff(dayjs(created)) / 1000);
        setDisplayTicks(elapsedSeconds);

        updateStars(elapsedSeconds);

        if (maxTicks > 0 && elapsedSeconds >= maxTicks)
            onTimerFinish();

        // Cleanup function
        return () => { }
    }, [timerTicks, maxTicks]);

    useEffect(() =>
    {
        setMaxTicks(Math.floor(dayjs(expires).diff(dayjs(created)) / 1000));

        // Cleanup function
        return () => { }
    }, [expires, created]);

    useEffect(() =>
    {
        if (hasSessionStarted && !hasSessionCompleted)
        {
            let newTimer = setInterval(() => setTimerTicks(prevTicks => prevTicks + 1), 1000);
            setTimer(newTimer);
        }

        if (hasSessionCompleted)
        {
            clearInterval(timer);
        }

        // Cleanup function
        return () =>
        {
            clearInterval(timer);
        }
    }, [hasSessionStarted, hasSessionCompleted]);

    const updateStars = elapsedSeconds =>
    {
        let newStars = 0;
        if (elapsedSeconds > STARS_1)
            newStars = 1;
        if (elapsedSeconds > STARS_2)
            newStars = 2;
        if (elapsedSeconds > STARS_3)
            newStars = 3;

        if (stars !== newStars)
        {
            setStars(newStars);
            onStarsChange(newStars);
        }
    }

    return (
        <AppBar
            position="sticky"
            variant='blurry'
            color='lightBlue'
        >
            <BorderLinearProgress currentValue={displayTicks} max={maxTicks} min={0} step={1} />
            <Typography variant="h5" sx={{ color: '#ffffffbb', position: 'absolute', top: 25, left: '50%', transform: 'translateX(-50%)' }}>
                {hasSessionCompleted ? 'SESSION COMPLETED!' : 'Session in progress...'}
            </Typography>
            {!hasSessionCompleted && <IconButton onClick={onComplete} sx={{ position: 'absolute', top: '16px', alignSelf: 'flex-start' }}>
                <CloseRoundedIcon />
            </IconButton>}
        </AppBar>
    );
}

export default ResponsiveAppBar;
