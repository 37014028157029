import { createTheme } from '@mui/material/styles';

export const themeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#c471f5',
        },
        secondary: {
            main: '#fa70cd',
        },
        lightRed: {
            main: '#C96767',
        },
        lightBlue: {
            main: '#273552',
        },
        darkGreen: {
            main: '#223824',
        },
        lightGreen: {
            main: '#5FC29D',
        },
        lightestBlue: {
            main: '#9BBEFF'
        },
        background: {
            default: '#1C263B',
        },
        lightGrey: {
            main: '#CCCCCC',
        },
        darkGrey: {
            main: '#888888',
        },
        whiteTransparent: {
            main: '#ffffff80',
        },
        white: {
            main: '#ffffff'
        },
        text: {
            primary: 'rgba(255,255,255,0.8)',
            secondary: '#fa70cd',
            lightGreen: '#78BD7B'
        },
    },
    typography: {
        fontFamily: 'Montserrat, Open Sans, Verdana, sans-serif',
        fontSize: 14,
        fontWeight: '300',
        
        h1: {
            fontSize: '32px',
            color: 'white',
            fontWeight: '300',
        },
        h2: {
            fontSize: '26px',
            color: 'white',
            fontWeight: '300',
        },
        h3: {
            fontSize: '20px',
            color: 'white',
            fontWeight: '300',
        },
        h4: {
            fontSize: '18px',
            color: 'white',
            fontWeight: '300',
        },
        h5: {
            fontSize: '16px',
            color: 'white',
            fontWeight: '300',
        },
        h6: {
            fontSize: '14px',
            color: 'white',
            fontWeight: '300',
        },
        session_message: {
            textAlign: 'left',
            whiteSpace: 'pre-wrap',
            textTransform: 'none',
            fontSize: '15px',
            fontWeight: 300,
            lineHeight: '110%',
            display:'block',
        },
        session_summary: {
            fontSize: '14px',
            textAlign: 'left',
            whiteSpace: 'pre-wrap',
            color: '#ffffff90',
        },
        long_session_summary: {
            fontSize: '16px',
            textAlign: 'left',
            whiteSpace: 'pre-wrap',
            color: "white",
            fontWeight: '300',
        },
        long_session_summary_bold: {
            fontSize: '16px',
            textAlign: 'left',
            whiteSpace: 'pre-wrap',
            color: "#c471f5",
            fontWeight: '500',
        },
        timeline_date: {
            fontWeight: '600',
            fontSize: '10px',
            color: '#ffffff50',
        },
        timeline_title: {
            fontWeight: '300',
            fontSize: '14px',
            whiteSpace: 'pre-wrap',
            color: 'white',
        },
        timeline_stars: {
            fontWeight: '300',
            fontSize: '14px',
            whiteSpace: 'pre-wrap',
            color: '#ffa726',
        },
        tokens_save: {
            fontWeight: '700',
            fontSize: '10px',
            color: '#ffffff90',
        },
        account_stat: {
            fontWeight: '300',
            fontSize: '12px',
            textAlign: 'center',
            color: '#ffffff90',
        },
        survey_question: {
            fontWeight: '300',
            fontSize: '14px',
            textAlign: 'left',
            color: '#ffffff',
        },
        debug: {
            fontSize: '8px',
            color: 'grey',
            whiteSpace: 'pre-wrap',
        }
    },
    overrides: {
        
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {},
                base_button: {
                    background: 'transparent',
                    border: 0,
                    borderRadius: 15,
                    boxShadow: 'none',
                    padding: '0px',
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '24px'
                },
                login: {
                    background: '#FFFFFF',
                    border: 0,
                    borderRadius: 15,
                    boxShadow: 'none',
                    color: 'black',
                    padding: '10px 20px 10px 20px',
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '16px'
                },
                bright: {
                    background: 'linear-gradient(45deg, #c471f5 30%, #fa70cd 90%)',
                    border: 0,
                    borderRadius: 15,
                    boxShadow: '0 0 10px 1px #D377FF',
                    color: 'white',
                    padding: '20px 20px',
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '24px'
                },
                bright_round: {
                    background: 'linear-gradient(45deg, #c471f5 30%, #fa70cd 90%)',
                    border: 0,
                    borderRadius: 40,
                    boxShadow: '0 0 10px 1px #D377FF',
                    color: 'white',
                    padding: '20px 20px',
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '24px'
                },
                timeline_bright: {
                    background: 'linear-gradient(45deg, #c471f5 30%, #fa70cd 90%)',
                    border: 0,
                    borderRadius: 15,
                    boxShadow: '0 0 10px 1px #D377FF',
                    color: 'white',
                    padding: '15px 20px',
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '24px',
                    marginLeft: '15px',
                },
                timeline_outline_premium: {
                    background: '#C571F620',
                    border: 0,
                    borderRadius: 15,
                    boxShadow: '0 0 5px 0px #C571F6',
                    color: '#C571F6',
                    padding: '15px 20px',
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: 1.1,
                    textAlign: 'left',
                    marginLeft: '15px',
                },
                timeline_button: {
                    background: 'transparent',
                    border: 'none',
                    padding: '15px 15px 15px 15px', /* top, right, bottom, left */
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: 1.2,
                    textAlign: 'left',
                    justifyContent: 'left'
                },
                outline: {
                    background: 'transparent',
                    border: 0,
                    borderRadius: 15,
                    boxShadow: '0 0 5px 0px #9BBEFF',
                    color: '#9BBEFF',
                    padding: '20px 20px 20px 20px', /* top, right, bottom, left */
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: 1.1,
                    textAlign: 'justify',
                },
                outline_completed: {
                    background: 'transparent',
                    border: 0,
                    borderRadius: 15,
                    boxShadow: '0 0 5px 0px #78BD7B',
                    color: '#78BD7B',
                    padding: '20px 20px 20px 20px', /* top, right, bottom, left */
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: 1.1,
                    textAlign: 'justify',
                },
                outline_premium: {
                    background: '#C571F620',
                    border: 0,
                    borderRadius: 15,
                    boxShadow: '0 0 5px 0px #C571F6',
                    color: '#C571F6',
                    padding: '20px 20px 20px 20px', /* top, right, bottom, left */
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: 1.1,
                    textAlign: 'left',
                },
                session_completed: {
                    background: 'linear-gradient(45deg, #1c7120 30%, #1f9225 90%)',
                    border: 0,
                    borderRadius: 15,
                    boxShadow: '0 0 5px 0px #1f9225',
                    color: '#FFFFFF',
                    padding: '30px 30px 30px 30px', /* top, right, bottom, left */
                    textTransform: 'none',
                    fontWeight: 300,
                    fontSize: '18px',
                    lineHeight: 1.1,
                    textAlign: 'justify',
                },
                top_up: {
                    background: 'linear-gradient(45deg, #c471f5 30%, #fa70cd 90%)',
                    border: 0,
                    borderRadius: 15,
                    boxShadow: '0 0 5px 0px #C571F6',
                    color: '#C571F6',
                    padding: '10px 10px 10px 10px', /* top, right, bottom, left */
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: 1.1,
                    textAlign: 'justify',
                },
                outline_round: {
                    background: 'transparent',
                    border: 0,
                    borderRadius: 40,
                    boxShadow: '0 0 5px 0px #9BBEFF',
                    color: '#9BBEFF',
                    padding: '20px 20px 20px 20px', /* top, right, bottom, left */
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: 1.1,
                    textAlign: 'justify',
                },
                playpause_olivya: {
                    background: 'transparent',
                    border: 0,
                    borderRadius: 0,
                    /*boxShadow: '0 0 5px 0px #C571F6',*/
                    padding: '0px 20px 0px 0px', /* top, right, bottom, left */
                    minWidth: 24,
                    color: 'grey'
                },
                playpause_my: {
                    background: 'transparent',
                    border: 0,
                    borderRadius: 40,
                    boxShadow: '0 0 5px 0px #9BBEFF',
                },
                short_basic: {
                    background: '#144bb1',
                    border: 0,
                    borderRadius: 10,
                    boxShadow: '0 0 5px 0px #144bb1',
                    color: 'white',
                    padding: '10px 20px 10px 20px', /* top, right, bottom, left */
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: 1.1,
                    textAlign: 'justify',
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    background: 'transparent',
                    borderRadius: 0,
                    padding: 0,
                    boxShadow: 'none',
                    textTransform: 'none',
                    textAlign: 'left',
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {},
                dateMessage: {
                    background: '#5d5d5d',
                    boxShadow: '-1px 2px 2px 2px rgb(0 0 0 / 20%)',
                },
                tokensDisplay: {
                    background: '#FFFFFF30',
                    //boxShadow: '-1px 2px 2px 2px rgb(0 0 0 / 20%)',
                    boxShadow: 'none',
                    padding: '5px',
                },
                myMessage: {
                    background: 'transparent',
                    borderBottom: '1px solid #9BBEFF',
                    borderTop: '1px solid #9BBEFF',
                    borderRadius: 15,
                    boxShadow: '0 3px 10px 0px #556b95',
                    color: '#9BBEFF',
                    padding: '15px 15px 15px 15px', /* top, right, bottom, left */
                    
                },
                myMessageVAD: {
                    background: 'transparent',
                    borderBottom: '1px solid #9BBEFF',
                    borderRadius: 15,
                    //boxShadow: '0 0 3px 0px #9BBEFF',
                    color: '#9BBEFF',
                    padding: '0px 20px 10px 20px', /* top, right, bottom, left */
                },
                olivyaMessage: {
                    background: 'transparent',
                    borderBottom: '1px solid #C571F6',
                    borderTop: '1px solid #C571F6',
                    borderRadius: 15,
                    boxShadow: '0 3px 10px 0px #743997',
                    color: '#C571F6',
                    padding: '15px 15px 15px 15px', /* top, right, bottom, left */
                    
                },
                olivyaMessageVAD: {
                    background: 'transparent',
                    borderBottom: '1px solid #C571F6',
                    borderRadius: 15,
                    //boxShadow: '0 0 5px 0px #C571F6',
                    color: '#C571F6',
                    padding: '0px 20px 10px 20px', /* top, right, bottom, left */
                },
                report_goals: {
                    background: 'transparent',
                    borderBottom: '1px solid #9BBEFF',
                    borderTop: '1px solid #9BBEFF',
                    borderRadius: 15,
                    boxShadow: '0 3px 10px 0px #556b95',
                    color: '#9BBEFF',
                    padding: '15px 15px 15px 15px', /* top, right, bottom, left */

                },
                success: {
                    background: '#5FC29D',
                    borderRadius: 15,
                    //boxShadow: '0 0 5px 0px #C571F6',
                    color: '#5FC29D',
                    padding: '20px 20px 20px 20px', /* top, right, bottom, left */
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: 1.1,
                    textAlign: 'center',
                },
                order_error: {
                    background: '#C25F5F',
                    borderRadius: 15,
                    //boxShadow: '0 0 5px 0px #C571F6',
                    padding: '20px 20px 20px 20px', /* top, right, bottom, left */
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: 1.1,
                    textAlign: 'center',
                },
                blurry: {
                    borderBottomLeftRadius: '20px',
                    borderBottomRightRadius: '20px',
                    padding: '20px 0px 20px 0px', /* top, right, bottom, left */
                    backdropFilter: 'blur(8px)',
                    background: 'transparent',
                    //boxShadow: '0 0 20px 10px rgba(0, 0, 0, 0.2)',
                    boxShadow: 'none',
                },
            },
        },
    },
    shape: {
        borderRadius: 8,
    },
};


export const theme = createTheme(themeOptions);