import * as amplitude from '@amplitude/analytics-browser';

amplitude.init('9e7fa344f84652eea4b656cf8ae0a4f3', { autocapture: { elementInteractions: true } });

export const am_setUserId = (userId) =>
{
    amplitude.setUserId(userId);
}

export const am_logEvent = (name, value) =>
{
    amplitude.track(name, value);
    //console.log("Event: " + name + " -> ", value );
}