import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getTimeOfDayCSSClass } from '../../app/dateUtils';
import Spacer from '../common/Spacer';
function LoadSession(props)
{
    const [isLoadingClicked, setIsLoadingClicked] = useState(false);

    const onStart = e =>
    {
        props.onClose();
        setIsLoadingClicked(true);
    }
    
    return (
        <div style={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            zIndex: 999999,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        }} className={getTimeOfDayCSSClass()}>

            <Container disableGutters maxWidth="sm" >
                <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ height: '100vh' }}
                >

                    <Spacer height={50} />
                    <Typography variant="h3">Your session is starting soon...</Typography>
                    <img src="/img/load-session.png" alt="loading" width="150" height="150" />
                    <Box
                        direction="column"
                        justifyContent="flex-start"
                        width='80%'
                    >
                        <Typography variant="h5">Remember:</Typography>
                        <Spacer height={5} />
                        <Typography variant="h6">- The session lasts 30 minutes</Typography>
                        <Spacer height={0} />
                        <Typography variant="h6">- Make sure you are in a quiet environment</Typography>
                        <Spacer height={0} />
                        <Typography variant="h6">- Talk to Olivya like you would with a coach</Typography>
                        <Spacer height={0} />
                        <Typography variant="h6">- This is your safe place!</Typography>
                    </Box>
                    {props.isLoaded && (<Button disabled={isLoadingClicked} variant="bright" onClick={onStart}>{isLoadingClicked ? "Starting..." : "Start Session"}</Button>)}
                    {!props.isLoaded && (<div className={"loading-screen-session"}>
                        <div className="loading-spinner">
                            <svg viewBox="0 0 100 100">
                                <circle cx="50" cy="50" r="40" />
                            </svg>
                        </div>
                        <Typography variant="h4">Loading {props.progress}%</Typography>
                    </div>)}
                    <Spacer height={50} />
                </Grid>
            </Container>
        </div>
    );
}

export default LoadSession;
