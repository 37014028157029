import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { Button, Container, Grid, IconButton, Stack, ToggleButtonGroup, Typography } from '@mui/material';
import { default as React, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from 'react-router-dom';
import { fs_updateUser } from '../../app/firestore';
import Spacer from '../common/Spacer';
import { getUser, setUserProperties } from '../user/UserSlice';
import AnswerCheckBox from './AnswerCheckBox';
import TutorialProgress from './TutorialProgress';
import { useEffect } from 'react';
import { am_logEvent } from '../../app/amplitude';

function Onboarding(props)
{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(getUser);

    const [getCurrentPage, setCurrentPage] = useState(0);
    const [getSurvey, setSurvey] = useState({ goals: [], why: [], challenges: [] });

    useEffect(() =>
    {
        window.scrollTo(0, 0);
        am_logEvent('funnel_open_onboarding');
        // cleanup function
        return () => { };
    }, []);

    const onNextPageClick = () =>
    {
        if (getCurrentPage === 4) // Finish onboarding
        {
            const newUser = {
                survey: getSurvey,
                onboarding: false
            };
            fs_updateUser(user.uid, newUser, () =>
            {
                dispatch(setUserProperties(newUser));
                am_logEvent('funnel_finish_onboarding');
                navigate('/', { replace: true });
            })
        }
        else
            setCurrentPage(getCurrentPage + 1);
    }

    const onPreviousPageClick = () =>
    {
        if(getCurrentPage >= 1)
            setCurrentPage(getCurrentPage - 1);
    }
    
    const onGoalsChange = (event, newValues) =>
    {
        let newSurvey = { ...getSurvey };
        newSurvey.goals = newValues;
        setSurvey(newSurvey);
    };

    const onWhyChange = (event, newValues) =>
    {
        let newSurvey = { ...getSurvey };
        newSurvey.why = newValues;
        setSurvey(newSurvey);
    };

    const onChallengesChange = (event, newValues) =>
    {
        let newSurvey = { ...getSurvey };
        newSurvey.challenges = newValues;
        setSurvey(newSurvey);
    };

    return (
        <Container disableGutters maxWidth="sm">
            <Spacer height={5} />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                {getCurrentPage > 0 && (<IconButton onClick={onPreviousPageClick}>
                    <ArrowBackIosNewRoundedIcon />
                </IconButton>)}
                {getCurrentPage === 0 && <Spacer width={40} height={40} />}
                    <TutorialProgress currentValue={getCurrentPage + 1} maxValue={5} />
                <Spacer width={40} />
            </Grid>

            <Spacer height={10} />
            
            <Carousel
                selectedItem={getCurrentPage}
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                showIndicators={false}
                autoPlay={false}
                dynamicHeight={true}
                useKeyboardArrows={false}
                swipeable={false}
                axis='horizontal'>

                <Stack justifyContent="center" alignItems="center">
                    <Spacer height={10} />
                    <Typography variant="h1" sx={{ textAlign: 'center' }}>Welcome {user.name.split(' ')[0]}!</Typography>
                    <Spacer height={10} />
                    <img src="/img/transparent.png" alt="transparent" style={{ width: '300px', height: '350px', position: 'absolute' }} />
                    <Spacer height={10} />
                    <Typography variant="h4" sx={{ textAlign: 'center' }}>Your journey to unlocking your potential begins here.</Typography>
                    <Spacer height={10} />
                    <img src="/img/unlock-potential.png" alt="unlock potential" style={{ width: '65%', height: 'auto' }} />
                    <Typography variant="h6" style={{ width: '70%' }}>Let's start with a few short questions</Typography>
                </Stack>

                <Stack justifyContent="center" alignItems="center" >
                    <Typography variant="h2" sx={{ textAlign: 'center', width: '330px' }}>What are your primary goals with Olivya?</Typography>
                    <Spacer height={10} />
                    <Typography variant="account_stat" sx={{ textAlign: 'center' }}>Select all that applies</Typography>
                    <Spacer height={10} />
                    <ToggleButtonGroup
                        orientation="vertical"
                        value={getSurvey.goals}
                        onChange={onGoalsChange}
                    >
                        <AnswerCheckBox label='Advance my career' value={getSurvey.goals} />
                        <AnswerCheckBox label='Improve my work-life balance' value={getSurvey.goals} />
                        <AnswerCheckBox label='Improve my work performance' value={getSurvey.goals} />
                        <AnswerCheckBox label='Boost my self-confidence' value={getSurvey.goals} />
                        <AnswerCheckBox label='Make a life change' value={getSurvey.goals} />
                        <AnswerCheckBox label='Polish my communication skills' value={getSurvey.goals} />
                        <AnswerCheckBox label='Improve my self-awareness' value={getSurvey.goals} />
                    </ToggleButtonGroup>
                    <Spacer height={40} />
                </Stack>

                <Stack justifyContent="center" alignItems="center" >
                    <Typography variant="h2" sx={{ textAlign: 'center', width: '330px' }}>Why are you looking for coaching?</Typography>
                    <Spacer height={10} />
                    <Typography variant="account_stat" sx={{ textAlign: 'center' }}>Select all that applies</Typography>
                    <Spacer height={10} />
                    <ToggleButtonGroup
                        orientation="vertical"
                        value={getSurvey.why}
                        onChange={onWhyChange}
                        
                    >
                        <AnswerCheckBox label='Reduce my stress' value={getSurvey.why} />
                        <AnswerCheckBox label='Stop feeling stuck' value={getSurvey.why} />
                        <AnswerCheckBox label='Stop feeling overwhelmed' value={getSurvey.why} />
                        <AnswerCheckBox label='Be happier with my life' value={getSurvey.why} />
                        <AnswerCheckBox label='To feel more balanced' value={getSurvey.why} />
                        <AnswerCheckBox label='To feel more fulfilled' value={getSurvey.why} />
                        <AnswerCheckBox label='To be the best version of myself' value={getSurvey.why} />
                    </ToggleButtonGroup>
                    <Spacer height={40} />
                </Stack>

                <Stack justifyContent="center" alignItems="center" >
                    <Typography variant="h2" sx={{ textAlign: 'center', width: '330px' }}>What are the main challenges in achieving your goals?</Typography>
                    <Spacer height={10} />
                    <Typography variant="account_stat" sx={{ textAlign: 'center' }}>Select all that applies</Typography>
                    <Spacer height={10} />
                    <ToggleButtonGroup
                        orientation="vertical"
                        value={getSurvey.challenges}
                        onChange={onChallengesChange}
                    >
                        <AnswerCheckBox label='Lack of time' value={getSurvey.challenges} />
                        <AnswerCheckBox label='Lack of clarity' value={getSurvey.challenges} />
                        <AnswerCheckBox label='Too many priorities to solve now' value={getSurvey.challenges} />
                        <AnswerCheckBox label='lack of organisational methods and tools' value={getSurvey.challenges} />
                        <AnswerCheckBox label='lack of motivation / energy' value={getSurvey.challenges} />
                        <AnswerCheckBox label='I am not sure if it is important to me right now' value={getSurvey.challenges} />
                    </ToggleButtonGroup>
                    <Spacer height={40} />
                </Stack>

                <Stack justifyContent="center" alignItems="center">
                    <Spacer height={10} />
                    <Typography variant="h1" sx={{ textAlign: 'center' }}>You are all set!</Typography>
                    <Spacer height={10} />
                    <img src="/img/transparent.png" alt="transparent" style={{ width: '300px', height: '350px', position: 'absolute' }} />
                    <Spacer height={10} />
                    <Typography variant="h4" sx={{ textAlign: 'center' }}>Let's go!</Typography>
                    <Spacer height={10} />
                    <img src="/img/relationship-development.png" alt="all set" style={{ width: '65%', height: 'auto' }} />
                </Stack>

                </Carousel>
            <Stack justifyContent="center" alignItems="center" >
                <Button onClick={onNextPageClick} variant='short_basic'>Continue</Button>
            </Stack>
            <Spacer height={120} />
        </Container>
    );
}

export default Onboarding;
