import { Box, Paper, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { am_logEvent } from '../../app/amplitude';
import { fs_addOrder, fs_updateUserProperty } from '../../app/firestore';
import { getUser, setUserProperty } from '../user/UserSlice';
import PayPalTokenOrder from './PayPalTokenOrder';
import TopUpButton from './TopUpButton';

export default function TopUpTokens({ onFail, onSuccess })
{
    const user = useSelector(getUser);
    const dispatch = useDispatch();

    const [getWindowState, setWindowState] = React.useState('start');
    const [getOrder, setOrder] = React.useState({tokens:0, price:0});

    const onTopUpClick = order =>
    {
        setOrder(order);
        setWindowState('order');
    }

    const onCancelOrder = () =>
    {
        setOrder({ tokens: 0, price: 0 });
        setWindowState('start');
    }

    const onSuccessOrder = (orderDetails) =>
    {
        setWindowState('processing');

        console.log("onSuccessOrder received");
        const fsOrder = {
            user_id: user.uid,
            users_tokens_before: user.tokens,
            order_id: orderDetails.id,
            create_time: orderDetails.create_time,
            update_time: orderDetails.update_time,
            intent: orderDetails.intent,
            payer_id: orderDetails.payer.payer_id,
            payer_email: orderDetails.payer.email_address,
            purchased_tokens: getOrder.tokens,
            price: getOrder.price,
            status: orderDetails.status
        };
        console.log(fsOrder);
        fs_addOrder(fsOrder, () =>
        {
            console.log("added order to fs");
            fs_updateUserProperty(user, 'tokens', user.tokens + getOrder.tokens, () =>
            {
                dispatch(setUserProperty({ property: 'tokens', value: user.tokens + getOrder.tokens }));
                //ga_logEvent('earn_virtual_currency', { value: getOrder.tokens, virtual_currency_name: 'Tokens' });
                am_logEvent('purchase',
                    {
                        value: getOrder.price,
                        currency: 'EUR',
                        transaction_id: orderDetails.id,
                        items: [{
                            item_id: getOrder.tokens+"T",
                            item_name: getOrder.tokens + " Tokens",
                        }]
                    });
                setWindowState('success');
                console.log("updated user");
                //props.onSuccess();
            });
        });
    }

    const onError = err =>
    {
        setWindowState('error');
    }

    return (
        <Box sx={{ p: 0 }}>
            {getWindowState === 'start' &&
                (
                    <Stack alignItems='flex-start' spacing={1} sx={{ maxWidth: '350px' }}>
                        {user.tokens !== undefined && user.tokens === 0 && <Typography variant='h6'>You don't have any Tokens left!</Typography>}
                        {user.tokens > 0 && <Typography variant='h3'>You have {user.tokens} Tokens!</Typography>}
                        <Typography variant='session_summary'>Use Tokens to unlock extra sessions, extend session duration, and much more..</Typography>
                        <Typography variant='h6'>Choose your package:</Typography>
                        <TopUpButton onClick={onTopUpClick} tokens={5} currency='€' price={4.99} save='' />
                        <TopUpButton onClick={onTopUpClick} tokens={10} currency='€' price={8.99} save='SAVE 10%' />
                        <TopUpButton onClick={onTopUpClick} tokens={20} currency='€' price={14.99} save='SAVE 25%' />
                        <TopUpButton onClick={onTopUpClick} tokens={40} currency='€' price={24.99} save='SAVE 37%' />
                        <Typography variant='session_summary'>Confirm payment on the next page!</Typography>
                    </Stack>
                )}
            {getWindowState === 'order' &&
                (
                <Stack alignItems='flex-start' spacing={1} sx={{ maxWidth: '350px' }}>
                    <PayPalTokenOrder
                        order={getOrder}
                        onCancel={onCancelOrder}
                        onSuccess={onSuccessOrder}
                        onError={onError} />
                </Stack>
                )}
            {getWindowState === 'success' &&
                (
                    <Stack alignItems='flex-start' spacing={1} sx={{ maxWidth: '350px' }}>
                        <Paper variant='success'>
                            <Typography variant='h2'>You have succesfully purchased Tokens!</Typography>
                        </Paper>
                        <Typography variant='h3'>You now have {user.tokens} Tokens!</Typography>
                    </Stack>
                )}
            {getWindowState === 'error' &&
                (
                    <Stack alignItems='flex-start' spacing={1} sx={{ maxWidth: '350px' }}>
                    <Paper variant='order_error'>
                            <Typography variant='h2'>There was an error!</Typography>
                            <Typography variant='h3'>Please contact support</Typography>
                        </Paper>
                    </Stack>
                )}
            {getWindowState === 'processing' &&
                (
                    <Stack alignItems='flex-start' spacing={1} sx={{ maxWidth: '350px' }}>
                        <div className="loading-screen-session">
                            <div className="loading-spinner">
                                <svg viewBox="0 0 100 100">
                                    <circle cx="50" cy="50" r="40" />
                                </svg>
                            </div>
                            <Typography variant="h4">Processing your order...</Typography>
                        </div>
                        <Typography variant='h6' color='error'>DO NOT LEAVE OR REFRESH THIS PAGE!</Typography>
                    </Stack>
                )}
        </Box>
    );
}