import { Alert, AlertTitle, Backdrop, Button, Container, Stack, Snackbar } from '@mui/material';
import { default as React, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getError, setError } from '../user/UserSlice';

export default function ErrorDialog(props)
{
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onReload = () =>
    {
        dispatch(setError(undefined));
        navigate('/');
        window.location.reload();
    };

    const error = useSelector(getError);

    useEffect(() =>
    {
        // cleanup function
        return () =>
        {
        };
    }, [error]);

    const handleClose = () =>
    {
        dispatch(setError(undefined));
    }

    return (
        <Snackbar open={error !== undefined}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert
                onClose={handleClose}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
            >
                <AlertTitle>Uh oh.. something went wrong there!</AlertTitle>
                {error}
            </Alert>
        </Snackbar>
    );
}
